import axios, { AxiosError, AxiosResponse } from "axios";
import { get, sortBy as sort } from "lodash";
import moment from "moment";
import { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { APP_CONSTANT, TIME_ZONE } from "../constants/AppConstant";
import { ToasterContext, UserContext } from "../contexts";
import {
    AddOrDeleteReactionRequestType,
    CommentDataType,
    CommentReactionResponseType,
    CommentsRequestType,
    ICommentResponse,
    IDeleteRequestType,
    IReplyRequestType,
    IRowType,
    ISnackBarMessage,
    ITreeCommentResponse,
    PageType,
    ProcessedReactionType,
    ReactionUserType,
} from "../types";
import { messages, processCommentsData } from "../utils";

const COMMENTS = "comments";

axios.defaults.baseURL = APP_CONSTANT.baseUrl;
// TODO: need to check if this hook function is in use anymore.
// Remove if it isn't
const useFetchComments = (requestType: CommentsRequestType, user: any) => {
    const [refetchInterval, setRefetchInterval] = useState(5 * 1000);
    return useQuery<ICommentResponse, Error>(
        [COMMENTS, requestType],
        () => axios.post(APP_CONSTANT.webApi.comments, requestType),
        {
            retry: 3,
            retryDelay: 3,
            cacheTime: 5 * 1000,
            staleTime: 5 * 1000,
            refetchInterval,
            onSettled: (
                commentsResponse: ICommentResponse | undefined,
                error: Error | null
            ): ICommentResponse | null | Error => {
                if (commentsResponse) {
                    const temp = [...commentsResponse.data.comments.comments];
                    commentsResponse.data.comments.comments.forEach((comment: IRowType) => {
                        if (!comment.clientMeta) {
                            const index = temp.findIndex(
                                (element) => element.id === comment.parentId
                            );
                            if (index > -1) {
                                comment.orderId = temp[index].orderId;
                                comment.createdAt = moment(comment.createdAt).format(
                                    "DD-MM-YYYY HH:MM:SS"
                                );
                                comment.myComment =
                                    user.id.toString() === comment.user.id ? "Y" : "N";
                                comment.userName = comment.user.handle;
                            }
                        } else {
                            comment.commentLocation = `${APP_CONSTANT.figmaUrl}/file/${comment.fileKey}?node-id=${comment.clientMeta.nodeId}#${comment.id}`;
                            comment.createdAt = moment(comment.createdAt).format(
                                "DD-MM-YYYY HH:MM:SS"
                            );
                            comment.myComment = user.id.toString() === comment.user.id ? "Y" : "N";
                            comment.userName = comment.user.handle;
                        }
                    });
                    commentsResponse.data.comments.comments = sort(
                        commentsResponse.data.comments.comments,
                        [(comment: IRowType) => comment.createdAt]
                    );
                    return commentsResponse;
                }

                if (error) {
                    setRefetchInterval(0);
                    return error;
                }
                return null;
            },
        }
    );
};

const useFetchTreeComments = (filterTye: string, props: ISnackBarMessage, branchKey: any) => {
    const { token, user } = UserContext();
    const {
        projectId,
    }: {
        projectId: string;
    } = useParams();
    const [refetchInterval, setRefetchInterval] = useState(60 * 5 * 1000);
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            Timezone: TIME_ZONE,
        },
    };
    return useQuery<ITreeCommentResponse, AxiosError>(
        [COMMENTS, projectId],
        () => axios.post(APP_CONSTANT.webApi.comments, { projectId, branchKey }, axiosConfig),
        {
            retry: 3,
            retryDelay: 3,
            cacheTime: 60 * 5 * 1000,
            staleTime: 60 * 5 * 1000,
            refetchInterval,

            onSettled: (
                commentsResponse: ITreeCommentResponse | undefined,
                error: AxiosError | null
            ): ITreeCommentResponse | null | AxiosError => {
                if (commentsResponse) {
                    return processCommentsData(commentsResponse, filterTye, user);
                }

                if (error) {
                    if (props.snackbarShowMessage) {
                        props.snackbarShowMessage(error?.response?.data?.message, "error");
                    }

                    setRefetchInterval(0);
                    return error;
                }
                return null;
            },
        }
    );
};

const callReplyApi =
    (token: string) =>
    (body: IReplyRequestType): any =>
        axios.post(APP_CONSTANT.webApi.reply, body, {
            headers: {
                Authorization: `Bearer ${token}`,
                Timezone: TIME_ZONE,
            },
        });

const callDeleteApi =
    (token: string) =>
    (body: IDeleteRequestType): any =>
        axios.post(APP_CONSTANT.webApi.delete, body, {
            headers: {
                Authorization: `Bearer ${token}`,
                Timezone: TIME_ZONE,
            },
        });

const useDeleteComment = (props: ISnackBarMessage) => {
    const { token } = UserContext();
    return useMutation(callDeleteApi(token), {
        onSettled: (
            response: AxiosResponse | undefined,
            error: AxiosError | null
        ): any | null | AxiosError => {
            if (response) {
                if (props.snackbarShowMessage) {
                    props.snackbarShowMessage(response?.data?.message, "success");
                }
                return response;
            }
            if (error) {
                if (props.snackbarShowMessage) {
                    props.snackbarShowMessage(error?.response?.data?.message, "error");
                }

                return error;
            }
            return null;
        },
    });
};

const useReactionMutation = () => {
    const { token, user } = UserContext();
    const { showToast } = useContext(ToasterContext);
    const queryClient = useQueryClient();

    const getReaction = useMutation(
        (reactionDetails: any) =>
            axios.post(
                APP_CONSTANT.webApi.getReaction,
                {
                    projectId: reactionDetails.projectId,
                    commentId: reactionDetails.commentId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Timezone: TIME_ZONE,
                    },
                }
            ),
        {
            onSettled: (response: any, error: any) => {
                if (error) {
                    const message = get(
                        error,
                        "response.data.message",
                        messages.SOMETHING_WENT_WRONG
                    );
                    showToast({ message, severity: "error" });
                    return error;
                }

                showToast({ message: response?.data?.message });
                return response;
            },
        }
    );

    const addReaction = useMutation(
        (payload: AddOrDeleteReactionRequestType) =>
            axios.post(APP_CONSTANT.webApi.addReaction, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Timezone: TIME_ZONE,
                },
            }),
        {
            onSettled: async (response, error, variables) => {
                if (error) {
                    const message = get(
                        error,
                        "response.data.message",
                        messages.SOMETHING_WENT_WRONG
                    );
                    showToast({ message, severity: "error" });
                    return error;
                }
                const { projectId, commentId, emoji } = variables;
                const queryName = [COMMENTS, projectId];
                await queryClient.cancelQueries(queryName);
                const previousData = await queryClient.getQueryData(queryName);
                const comments = get(previousData, "data.comments.comments.comments", []);
                comments.forEach((comment: any) => {
                    if (comment.id === commentId) {
                        comment.reactions = [
                            ...comment.reactions,
                            {
                                emoji,
                                createdAt: new Date().toISOString(),
                                user: {
                                    id: user.figmaUserId,
                                    handle: user.name,
                                    imgUrl: user.avatar,
                                },
                            },
                        ];
                    }
                });
                queryClient.invalidateQueries([COMMENTS]);
                return response;
            },
        }
    );

    const deleteReaction = useMutation(
        (payload: AddOrDeleteReactionRequestType) =>
            axios.post(APP_CONSTANT.webApi.deleteReaction, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Timezone: TIME_ZONE,
                },
            }),
        {
            onSettled: async (response, error, variables) => {
                if (error) {
                    const message = get(
                        error,
                        "response.data.message",
                        messages.SOMETHING_WENT_WRONG
                    );
                    showToast({ message, severity: "error" });
                    return error;
                }
                const { projectId, commentId, emoji } = variables;
                const queryName = [COMMENTS, projectId];
                await queryClient.cancelQueries(queryName);
                const previousData = await queryClient.getQueryData(queryName);
                const comments = get(previousData, "data.comments.comments.comments", []);
                comments.forEach((comment: any) => {
                    if (comment.id === commentId) {
                        comment.reactions = comment.reactions.filter(
                            (reaction: {
                                user: {
                                    id: string;
                                    handle: string;
                                    imgUrl: string;
                                };
                                emoji: string;
                                createdAt: string;
                            }) => reaction.emoji !== emoji
                        );
                    }
                });
                queryClient.invalidateQueries([COMMENTS]);
                return response;
            },
        }
    );
    return { getReaction, addReaction, deleteReaction };
};

const useReplyOnComment = (props: ISnackBarMessage) => {
    const { token } = UserContext();
    return useMutation(callReplyApi(token), {
        onSettled: (response: AxiosResponse | undefined, error: AxiosError | null): any => {
            if (response) {
                if (props.snackbarShowMessage) {
                    props.snackbarShowMessage(response?.data?.message, "success");
                }
                return response;
            }
            if (error) {
                if (props.snackbarShowMessage) {
                    props.snackbarShowMessage(error?.response?.data?.message, "error");
                }
                return error;
            }
            return null;
        },
    });
};

const postMarkCommentsAsRead =
    (token: string) =>
    (body: any): any =>
        axios.post(APP_CONSTANT.webApi.markAsRead, body, {
            headers: {
                Authorization: `Bearer ${token}`,
                Timezone: TIME_ZONE,
            },
        });

const useMarkCommentsAsRead = (props: ISnackBarMessage) => {
    const { token } = UserContext();
    const queryClient = useQueryClient();
    const {
        projectId,
    }: {
        projectId: string;
    } = useParams();
    return useMutation(postMarkCommentsAsRead(token), {
        onSettled: (response: AxiosResponse | undefined, error: AxiosError | null): any => {
            if (response) {
                if (props.snackbarShowMessage) {
                    props.snackbarShowMessage(response.data.message, "success");
                }

                queryClient.invalidateQueries([COMMENTS, projectId]);

                return response;
            }
            if (error) {
                if (props.snackbarShowMessage) {
                    props.snackbarShowMessage(error?.response?.data?.message, "error");
                }
                return error;
            }
            return null;
        },
    });
};

const useProcessCommentReactions = (reactions: CommentReactionResponseType[]) => {
    const processedReactions: ProcessedReactionType[] = [];
    reactions.forEach(({ emoji, user, createdAt }) => {
        const existingReaction = processedReactions.find((reaction) => reaction.emoji === emoji);
        const processedReaction =
            existingReaction ||
            ({
                emoji,
                users: [] as ReactionUserType[],
            } as ProcessedReactionType);
        processedReaction.users.push({ ...user, createdAt });
        if (!existingReaction) {
            processedReactions.push(processedReaction);
        }
    });
    // Sort Reaction users by createdAt
    processedReactions.forEach(({ users }) => {
        users.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    });
    return processedReactions;
};

// Hook function to process pages and comments data and retrieve comments count for each page and flow
const processPageAndPrototypeComments = ({
    comments = [],
    pages = [],
}: {
    comments: CommentDataType[];
    pages: PageType[];
}) => {
    // Create a map to hold comments for each nodeId
    const nodeCommentsMap = new Map();
    comments.forEach((comment) => {
        const { clientMeta } = comment;
        if (clientMeta) {
            const { nodeId } = clientMeta;
            if (nodeCommentsMap.has(nodeId)) {
                nodeCommentsMap.get(nodeId).push(comment);
            } else {
                nodeCommentsMap.set(nodeId, [comment]);
            }
        }
    });

    // Update the pages and flows with the actual comments
    const updatedPages = pages.map((page) => {
        if (page.node_id) {
            page.comments = nodeCommentsMap.get(page.node_id) || [];
        } else {
            page.comments = [];
        }

        const updatedFlows = page.flows.map((flow) => {
            if (flow.node_id) {
                flow.comments = nodeCommentsMap.get(flow.node_id) || [];
            } else {
                flow.comments = [];
            }
            return flow;
        });

        page.flows = updatedFlows;
        return page;
    });
    return updatedPages;
};

export {
    COMMENTS,
    processPageAndPrototypeComments,
    useDeleteComment,
    useFetchComments,
    useFetchTreeComments,
    useMarkCommentsAsRead,
    useProcessCommentReactions,
    useReactionMutation,
    useReplyOnComment,
};
