import axios, { AxiosError } from "axios";
import { get } from "lodash";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

import { APP_CONSTANT, TIME_ZONE } from "../constants/AppConstant";
import { ToasterContext } from "../contexts";
import { UserContext } from "../contexts/UserContext";
import { ISnackBarMessage, NotionOAuthPayloadDataType } from "../types";
import { api, messages } from "../utils";
import { USER } from "./usersHook";

interface IUpdateNotionDatabase {
    projectId: number;
    databaseId: string;
    notionFileName: string;
    figmaUrl: string;
    notionFilteredComments: any;
}

interface ICreateNotionDatabase {
    projectId: number;
    notionFileName: string;
    figmaUrl: string;
    notionFilteredComments: any;
}

interface ISyncComments {
    projectId: number;
}

const useNotionMutation = () => {
    const { token } = UserContext();
    const queryClient = useQueryClient();
    const { showToast } = useContext(ToasterContext);

    const notionOAuth = useMutation(
        async (payloadData: NotionOAuthPayloadDataType) => {
            const { data } = await api({
                url: APP_CONSTANT.webApi.notionAuth,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Timezone: TIME_ZONE,
                },
                data: payloadData,
            });
            return get(data, "data", {});
        },
        {
            onSettled: (response, error: AxiosError | null) => {
                if (error) {
                    const message = get(
                        error,
                        "response.data.message",
                        messages.SOMETHING_WENT_WRONG
                    );
                    showToast({ message, severity: "error" });
                    return error;
                }
                queryClient.invalidateQueries({
                    queryKey: [USER],
                    exact: true,
                });
                return response;
            },
        }
    );

    const acceptTermsOfUseAndPrivacyPolicy = useMutation(
        () =>
            axios.get(APP_CONSTANT.webApi.acceptTermsOfUseAndPrivacyPolicy, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Timezone: TIME_ZONE,
                },
            }),
        {
            onSettled: (response: any, error: any) => {
                if (error) {
                    const message = get(
                        error,
                        "response.data.message",
                        messages.SOMETHING_WENT_WRONG
                    );
                    showToast({ message, severity: "error" });
                    return error;
                }
                return response;
            },
        }
    );

    const disconnectNotion = useMutation(
        async () => {
            const { data } = await api({
                url: APP_CONSTANT.webApi.notionDisconnect,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Timezone: TIME_ZONE,
                },
            });
            return data;
        },
        {
            onSettled: async (response, error: AxiosError | null) => {
                if (error) {
                    const message = get(
                        error,
                        "response.data.message",
                        messages.SOMETHING_WENT_WRONG
                    );
                    showToast({ message, severity: "error" });
                    return error;
                }

                await queryClient.invalidateQueries({
                    queryKey: USER,
                    exact: true,
                });

                showToast({ message: response.message });
                return response;
            },
        }
    );

    return { acceptTermsOfUseAndPrivacyPolicy, notionOAuth, disconnectNotion };
};

const useProjectDisconnect = (props: ISnackBarMessage) => {
    const { token } = UserContext();

    const disconnectProject = useMutation(
        (projectId: number) =>
            axios.post(
                APP_CONSTANT.webApi.notionProjectDisconnect,
                { projectId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Timezone: TIME_ZONE,
                    },
                }
            ),
        {
            onSettled: (response: any, error: any) => {
                if (error) {
                    if (props.snackbarShowMessage) {
                        props.snackbarShowMessage(error?.response?.data?.message, "error");
                    }
                    return error;
                }
                if (props.snackbarShowMessage) {
                    props.snackbarShowMessage(response?.data?.message, "success");
                }
                return response;
            },
        }
    );
    return { disconnectProject };
};

const useProjectConnect = (props: ISnackBarMessage) => {
    const { token } = UserContext();

    const connectProject = useMutation(
        (projectId: number) =>
            axios.post(
                APP_CONSTANT.webApi.notionProjectConnect,
                { projectId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Timezone: TIME_ZONE,
                    },
                }
            ),
        {
            onSettled: (response: any, error: any) => {
                if (error) {
                    if (props.snackbarShowMessage) {
                        props.snackbarShowMessage(error?.response?.data?.message, "error");
                    }
                    return error;
                }
                if (props.snackbarShowMessage) {
                    props.snackbarShowMessage(response?.data?.message, "success");
                }
                return response;
            },
        }
    );
    return { connectProject };
};

const useCheckDatabaseExist = (props?: ISnackBarMessage) => {
    const { token } = UserContext();

    const databaseExist = useMutation(
        (projectId: number) =>
            axios.post(
                APP_CONSTANT.webApi.notionDatabaseExist,
                {
                    projectId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Timezone: TIME_ZONE,
                    },
                }
            ),
        {
            onSettled: (response: any, error: any) => {
                if (error) {
                    if (!props) return error;
                    if (props.snackbarShowMessage) {
                        props.snackbarShowMessage(error?.response?.data?.message, "error");
                    }
                    return error;
                }
                return response;
            },
        }
    );
    return { databaseExist };
};

const useUpdateDatabase = (props: ISnackBarMessage) => {
    const { token } = UserContext();

    const updateDatabase = useMutation(
        (notionUpdateDatebase: IUpdateNotionDatabase) =>
            axios.post(
                APP_CONSTANT.webApi.notionUpdateDatabase,
                {
                    databaseId: notionUpdateDatebase.databaseId,
                    projectId: notionUpdateDatebase.projectId,
                    databaseName: notionUpdateDatebase.notionFileName,
                    figmaUrl: notionUpdateDatebase.figmaUrl,
                    filteredComments: notionUpdateDatebase.notionFilteredComments,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Timezone: TIME_ZONE,
                    },
                }
            ),
        {
            onSettled: (response: any, error: any) => {
                if (error) {
                    if (props.snackbarShowMessage) {
                        props.snackbarShowMessage(error?.response?.data?.message, "error");
                    }
                    return error;
                }
                return response;
            },
        }
    );
    return { updateDatabase };
};

const useCreateDatabase = (props: ISnackBarMessage) => {
    const { token } = UserContext();

    const createDatabase = useMutation(
        (notionCreateDatebase: ICreateNotionDatabase) =>
            axios.post(
                APP_CONSTANT.webApi.notionCreateDatabase,
                {
                    projectId: notionCreateDatebase.projectId,
                    databaseName: notionCreateDatebase.notionFileName,
                    figmaUrl: notionCreateDatebase.figmaUrl,
                    filteredComments: notionCreateDatebase.notionFilteredComments,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Timezone: TIME_ZONE,
                    },
                }
            ),
        {
            onSettled: (response: any, error: any) => {
                if (error) {
                    if (props.snackbarShowMessage) {
                        props.snackbarShowMessage(error?.response?.data?.message, "error");
                    }
                    return error;
                }
                return response;
            },
        }
    );
    return { createDatabase };
};

const useSyncComments = (props: ISnackBarMessage) => {
    const { token } = UserContext();

    const syncComments = useMutation(
        (notionSyncComments: ISyncComments) =>
            axios.post(
                APP_CONSTANT.webApi.notionSyncComments,
                {
                    projectId: notionSyncComments.projectId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Timezone: TIME_ZONE,
                    },
                }
            ),
        {
            onSettled: (response: any, error: any) => {
                if (error) {
                    if (props.snackbarShowMessage) {
                        props.snackbarShowMessage(error?.response?.data?.message, "error");
                    }
                    return error;
                }
                return response;
            },
        }
    );
    return { syncComments };
};

export {
    useCheckDatabaseExist,
    useCreateDatabase,
    useNotionMutation,
    useProjectConnect,
    useProjectDisconnect,
    useSyncComments,
    useUpdateDatabase,
};
